import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { RESPONSIVE_STYLES } from '../../../../AppConst'

const Node = styled.div`
  //width: calc((100% - 40px) / 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    width: 100%;
    gap: 8px;
  }
`

const Label = styled.span`
  width: fit-content;
  padding: 4px 16px;
  background-color: #FFFFFF;

  font-size: 1.6rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #222222;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: 1.4rem;
    line-height: 21px;
  }
`

const ImageWrapper = styled.a`
  width: 100%;
  height: auto;
  transition: .3s;

  &:hover {
    opacity: .7;
  }
`

const StaticImg = styled.img`
  width: 100%;
  height: 100%;
  display: block;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: none;
  }
`

const StaticImgSp = styled.img`
  width: 100%;
  height: 100%;
  display: none;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: block;
  }
`

type Props = {
  img: any;
  imgSp: any;
  label: string;
  alt: string;
  path: string;
  className: string;
}

export const EngineerTrainingCard:React.VFC<Props> = (props) => {
  const blank = props.path === "javascript:void(0)" ? "" : "_blank"
  return (
    <Node>
      <Label>{props.label}</Label>
      <ImageWrapper href={props.path} target={blank} rel="noopener noreferrer" className={props.className}>
        <StaticImg src={props.img} alt={props.alt} />
        <StaticImgSp src={props.imgSp} alt={props.alt} />
      </ImageWrapper>
    </Node>
  )
}
